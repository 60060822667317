canvas {
  width: 100% !important;
}

.mainContainer {
  width: 100%;
  height: calc(100vh - 150px);
  position: relative;
}

.btnSelect {
  display: block;
  width: 225px;
  top: 100px;
  right: 40px;
  position: fixed;
  background: rgb(195, 22, 24);
  text-align: center;
  color: white;
  font-weight: bold;
  border-radius: 30px;
  line-height: 44px;
  font-size: 14px;
  inset: 65px 15px auto auto;
  cursor: pointer;
}

.spanBtnSelect {
  display: inline;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.spanBtnPrice {
  font-size: 14px;
  transition: all 0.4s ease 0s;
  margin-left: 10px;
  cursor: pointer;
}

.nameContainer {
  position: absolute;
  z-index: 1;
  border-radius: 12px;
  padding: 10px;
  font-weight: bold;
  font-size: 16px;
  top: 10%;
  left: 10%;
}

@media screen and (max-width: 800px) {
  .btnSelect {
    display: none;
  }
  .mainContainer {
    width: 100%;
    height: calc(100vh - 80px);
    position: relative;
  }
}
