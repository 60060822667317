@font-face {
  font-family: "MyFont";
  src: url("https://methyz.parel.co/customize/font/Mostardesign_SofiaProRegular.otf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "MyFont";
  src: url("https://methyz.parel.co/customize/front/Mostardesign_SofiaPro-Bold.otf")
    format("truetype");
  font-weight: bold;
  font-style: normal;
}
