.bottomtray {
  padding: 20px;
  position: absolute;
  bottom: 10px;
  width: 100vw;
  background-color: #fff;
  display: block;
}

.bottomtrayMobile {
  padding: 10px;
  position: absolute;
  bottom: 0px;
  width: 100vw;
  background-color: #fff;
  display: none;
}

.categoryContainer {
  width: 100%;
  align-items: center;
  justify-content: flex-start;

  font-size: 12px;
  line-height: 30px;
  font-weight: bold;
  margin-bottom: 10px;
}

.sizeContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.partSelect {
  padding: 10px;
  width: 100% !important;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.colorContainer {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  display: flex;
  width: 100%;
  scroll-behavior: smooth;
  justify-content: center;
  align-items: center;
}

.categoryBtn {
  width: 200px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-style: solid;
  border-width: thin;
  border-color: lightgrey;
}

.catActive {
  display: flex;
  justify-content: center;
  align-items: center;
  border-style: solid;
  border-width: thin;
  border-color: #00de08;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.colorbxFixedContainer {
  position: fixed;
  left: 0px;
  bottom: 5vh;
  width: 100%;
  display: flex;
  /* border: 1px solid lightgrey; */
}

.colorLeftContainer {
  flex: 1 1 0%;
  /* border-right: 1px solid lightgrey; */
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  line-height: 30px;
  font-weight: bold;
}

.modelCatContainer {
  padding: 20px;
  font-size: 14px;
  line-height: 30px;
  font-weight: 200;
  margin-bottom: 10px;
}

.cateName {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 10px;
  cursor: pointer;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  text-align: center;
  line-height: 1.2;
  word-wrap: break-word;
}

.colorRightContainer {
  flex: 3.7 1 0%;
  /* border-right: 1px solid lightgrey; */
  padding: 20px;
  position: relative;
}

.colorboxContainer {
  width: 100%;
  padding: 1px;
}

.colorbox {
  /* min-width: 60px; */
  height: 40px;
  cursor: pointer;
  flex: 1 1 0%;
  position: relative;
  transition: all 0.2s ease 0s;
}

.colorbox:hover {
  transform: scaleY(2.1);
  transition: transform 0.5s ease;
}

.colorboxSoleContainer {
  padding: 2px;
  flex: 1 1 0%;
}

.colorboxSoleTop {
  min-width: 30px;
  height: 20px;
  cursor: pointer;
  flex: 1 1 0%;
  position: relative;
}
.colorboxSoleBottom {
  min-width: 30px;
  height: 20px;
  cursor: pointer;
  flex: 1 1 0%;
  position: relative;
}

.colorboxSoleContainer:hover {
  transform: scaleY(1.1);
  transition: transform 0.5s ease;
}

.colorLeftContainermobile {
  display: none;
}

.sizemob {
  display: none;
}

.sizeContainerMob {
  display: none;
}

.smallText {
  font-size: 12px;
  cursor: pointer;
  line-height: 1.2;
}

.labelCustom {
  font-size: 12px;
  font-weight: bold;
  display: inline-block !important;
}

@media (max-width: 800px) {
  .colorbxFixedContainer {
    bottom: 0;
    border: none;
  }
  .colorLeftContainer {
    display: none;
  }

  .sizeContainer {
    display: none;
  }

  .modelCatContainer {
    display: none;
  }

  .colorRightContainer {
    padding: 0;
  }

  .colorboxContainerMob {
    position: fixed;
    left: 0px;
    bottom: 0;
    width: 100%;
    display: inline-block;
    border: 1px solid lightgrey;
  }

  .colorContainer {
    width: 100vw;
  }

  .colorLeftContainermobile {
    display: block;
    position: absolute;
    left: 45%;
    bottom: 20vh;
  }

  .floatBtn {
    min-height: 40px;
    background: white;
    width: 60%;
    right: 20%;
    font-size: 14px;
    font-weight: bold;
    border: 1px solid rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-content: center;
    z-index: 1;
  }

  .floatBtn span {
    padding: 5px;
  }

  .mobileMenuOpen {
    position: fixed;
    height: 200px;
    transition: height 1s ease;
    padding: 20px;
    z-index: 1;
  }

  .mobileMenuClose {
    position: fixed;
    height: 0;
  }

  .menuItem {
    overflow-y: auto;
    width: 100%;
    height: 100%;
  }

  .mobileItemOpen {
    padding: 10px;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .mobileItemClose {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .sizeContainerMob {
    padding: 10px;
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  .bottomtray {
    display: none;
  }

  .bottomtrayMobile {
    display: block;
  }

  .categoryContainer {
    align-items: center;
    justify-content: center;
    font-size: 12px;
  }

  .categoryBtn {
    width: 100%;
    height: 50px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-style: solid;
    border-width: thin;
    border-color: lightgrey;
  }

  .catActive {
    display: flex;
    justify-content: center;
    align-items: center;
    border-style: solid;
    border-width: thin;
    border-color: #00de08;
    background-color: #00de08;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  }

  .someContainer {
    width: 100%;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .btnSelectMob {
    width: 80%;
    background: #3200d2;
    text-align: center;
    color: white;
    font-weight: bold;
    border-radius: 30px;
    line-height: 44px;
    font-size: 14px;
    inset: 65px 15px auto auto;
    cursor: pointer;
  }

  .spanBtnSelectMob {
    display: inline;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}
