.topBar {
  width: 100%;
  height: 40px;
  background-color: #3200d2;
  display: block;
}

.iconLeft {
  width: 100%;
  height: 100%;
  background-color: rgb(23, 37, 33);
}

.iconRight {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  padding: 20px;
}

.iconRight a {
  text-decoration: none;
}

.bottomContainer {
  width: 100%;
  height: 100px;
  display: block;
}

.navMenuLeft {
  width: calc(100% / 3);
  height: 100%;
  display: block;
}

.navMenuLogo {
  width: calc(100% / 3);
  height: 100%;
  display: block;
  cursor: pointer;
}

.navMenuRight {
  width: calc(100% / 3);
  height: 100%;
  display: block;
}

.navmain {
  display: flex;
  text-align: left;
}

.nav-menu {
  list-style: none;
  margin: 0;
  padding: 0;
  z-index: 1;
}

.nav-menu li {
  margin: 0 15px;
  display: inline-block;
  position: relative;
}

.nav-menu li a {
  text-decoration: none;
  height: inherit;
  display: flex;
  align-items: center;
  transition: all 0.3s;
  font-family: "sofiaprobold, Arial, sans-serif" !important;
  font-style: normal;
  font-weight: bold;
  color: black;
}

.toNavText {
  border-radius: 12px;
  padding: 10px;
  font-weight: bolder;
  font-size: 26px;
}

.menu-item :hover {
  color: #00de08;
}

@media (max-width: 767px) {
  .topBar {
    display: none !important;
  }
  .navMenuLeft {
    display: none !important;
  }

  .navMenuRight {
    display: none !important;
  }

  .bottomContainer {
    height: 50px;
    justify-content: center !important;
    align-items: center;
  }

  .navMenuLeft {
    display: none;
  }

  .navMenuLogo {
    display: none;
  }

  .navMenuRight {
    display: none;
  }
}
